import { httpClient } from "config/http-client";
import { successMessage } from "helpers/toast";
import { errorHandler } from "helpers/http-helper";
import { PaginationMeta } from "components/table/custom-table";
import { PaymentTypeEnum } from "domain/schedule/enums/payment-type.enum";
import { ScheduleTypeEnum } from "domain/schedule/enums/schedule-type.enum";
import { ScheduleStatusEnum } from "domain/schedule/enums/schedule-status.enum";
import { ScheduleOriginContactEnum } from "domain/schedule/enums/schedule-origin-contact.enum";
import { ScheduleBlockedStatusEnum } from "~/domain/schedule/enums/schedule-blocked-status.enum";

export interface MakeScheduleProps {
  doctor_id: number | null;
  patient_id: number | null;
  patient_name: string | null;
  patient_phone: string | null;
  patient_document: string | null;
  status: ScheduleStatusEnum | null;
  start: string;
  end: string;
  type: ScheduleTypeEnum;
  observation: string;
  origin_contact: ScheduleOriginContactEnum | null;
  amount: number;
  is_phone_changed?: boolean;
  health_plan_id?: number;
}

export interface ListAllScheduleProps {
  start?: string;
  end?: string;
  doctor_id?: string;
  patient_name?: string;
  status?: string[];
  is_schedule_blocked?: ScheduleBlockedStatusEnum;
  limit: number;
  page: number;
}

export interface HealthPlanItem {
  id: number;
  name: string;
}
export interface ScheduleTypeItem {
  id: number;
  name: string;
}
export interface ListAllScheduleItems {
  id: number;
  start: string;
  end: string;
  status: ScheduleStatusEnum;
  type: ScheduleTypeEnum;
  origin_contact: ScheduleOriginContactEnum | null;
  patient: {
    id: number;
    name: string;
    phone: string;
  } | null;
  doctor: {
    id: number;
    name: string;
  };
  observation: string;
  amount: number;
  amount_paid: number;
  payment_type: PaymentTypeEnum;
  paid_at: string;
  payment_observation: string | null;
  health_plan: HealthPlanItem | null;
  schedule_type: ScheduleTypeItem | null;
  is_schedule_blocked: ScheduleBlockedStatusEnum;
}
export interface ListAllScheduleResponse {
  items: ListAllScheduleItems[];
  meta: PaginationMeta;
}

export interface UpdateStatusScheduleProps {
  status: ScheduleStatusEnum;
}

export interface MakeAppointmentPaymentProps {
  amount_paid: number;
  payment_type: PaymentTypeEnum;
  paid_at: string;
  payment_observation?: string;
  health_plan_id?: number;
}

export interface ListAllEstimatedReturnnAppointmentProps {
  start?: string;
  end?: string;
  doctor_id?: string;
  patient_name?: string;
  is_scheduled?: boolean;
  limit: number;
  page: number;
}

export interface ListAllEstimatedReturnAppointmentItems {
  id: number;
  current_appointment_date: string;
  estimated_return_date: string;
  estimated_return_observation: string | null;
  is_scheduled: boolean;
  patient: {
    id: number;
    name: string;
    phone: string;
  };
  doctor: {
    id: number;
    name: string;
  };
}
export interface ListAllEstimatedReturnAppointmentsResponse {
  items: ListAllEstimatedReturnAppointmentItems[];
  meta: PaginationMeta;
}

export class ScheduleService {
  static async makeSchedule(input: MakeScheduleProps): Promise<void> {
    try {
      const result = await httpClient.request({
        url: "/schedule",
        method: "post",
        data: input,
      });

      successMessage("Agendamento realizado com sucesso");
      return result.data;
    } catch (err) {
      errorHandler(err, "Falha ao agendar paciente");
      throw new Error("Falha ao agendar paciente");
    }
  }

  static async updateSchedule(
    id: string,
    input: MakeScheduleProps
  ): Promise<void> {
    try {
      const result = await httpClient.request({
        url: `/schedule/${id}`,
        method: "patch",
        data: input,
      });

      successMessage("Agendamento atualizado com sucesso");
      return result.data;
    } catch (err) {
      errorHandler(err, "Falha ao atualizar agendamento");
      throw new Error("Falha ao atualizar agendamento");
    }
  }

  static async listAllSchedule(
    input: ListAllScheduleProps
  ): Promise<ListAllScheduleResponse> {
    try {
      const result = await httpClient.request({
        url: "/schedule",
        method: "get",
        params: input,
      });

      return result.data as ListAllScheduleResponse;
    } catch (err) {
      errorHandler(err, "Falha ao consultar agenda");
      throw err;
    }
  }

  static async updateStatus(
    id: number,
    input: UpdateStatusScheduleProps
  ): Promise<void> {
    try {
      const result = await httpClient.request({
        url: `/schedule/status/${id}`,
        method: "patch",
        data: input,
      });

      successMessage("Agendamento atualizado com sucesso");
      return result.data;
    } catch (err) {
      errorHandler(err, "Falha ao atualizar agendamento");
      throw new Error("Falha ao atualizar agendamento");
    }
  }

  static async deleteSchedule(id: number): Promise<void> {
    try {
      const result = await httpClient.request({
        url: `/schedule/${id}`,
        method: "delete",
      });

      successMessage("Agendamento excluído com sucesso");
      return result.data;
    } catch (err) {
      errorHandler(err, "Falha ao excluir agendamento");
      throw new Error("Falha ao excluir agendamento");
    }
  }

  static async makeAppointmentPayment(
    id: number,
    input: MakeAppointmentPaymentProps
  ): Promise<void> {
    try {
      const result = await httpClient.request({
        url: `/schedule/pay/${id}`,
        method: "patch",
        data: input,
      });

      successMessage("Lançamento de pagamento realizado com sucesso");
      return result.data;
    } catch (err) {
      errorHandler(err, "Falha ao lançar pagamento");
      throw new Error("Falha ao lançar pagamento");
    }
  }

  static async listAllEstimatedReturnAppointments(
    input: ListAllEstimatedReturnnAppointmentProps
  ): Promise<ListAllEstimatedReturnAppointmentsResponse> {
    try {
      const result = await httpClient.request({
        url: "/schedule/estimated-return-appointments",
        method: "get",
        params: input,
      });

      return result.data as ListAllEstimatedReturnAppointmentsResponse;
    } catch (err) {
      errorHandler(err, "Falha ao buscar lista de retorno");
      throw err;
    }
  }
}
